<template>
  <section class="bando-head">
    <h1>Modifier un FSE</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">Informations du FSE</h2>

      <!-- INFOS -->
      <form @submit.prevent="create">
        <div class="bloc-infos bloc-shadow">
          <div class="form-group row">
            <label for="nom" class="col col-12 col-md-3">Nom*</label>
            <div class="col col-6 col-md-3">
              <input
                type="text"
                class="form-control"
                name="prenom"
                placeholder="Votre prénom"
                v-model="fse.prenom"
              />
            </div>
            <div class="col col-6 col-md-3">
              <input
                type="text"
                class="form-control"
                name="nom"
                placeholder="Votre nom"
                v-model="fse.nom"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="tel-mobile" class="col col-12 col-md-3">
              Téléphone*
            </label>
            <div class="col col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                name="tel-mobile"
                placeholder="Mobile"
                v-model="fse.telephone"
                size="14"
                maxlength="14"
                @keyup="autoTelMobile"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col col-12 col-md-3">Email*</label>
            <div class="col col-12 col-md-6">
              <input
                type="email"
                class="form-control"
                name="email"
                placeholder="xxx@yyy.com"
                v-model="fse.email"
              />
            </div>
          </div>
        </div>

        <p class="pl-5"><small>* Informations obligatoires</small></p>

        <!-- Buttons -->
        <div class="text-center mb-5 pb-5" v-if="isOperateur || isResponsable">
          <button
            type="reset"
            class="btn btn-secondary mx-3"
            @click="goTo('/liste-fse')"
          >
            Abandonner
          </button>
          <button type="submit" class="btn btn-primary mx-3">Valider</button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "FseEdit",
  components: {},
  data() {
    return {
      fse: {
        nom: "",
        prenom: "",
        telephone: "",
        email: ""
      },
      adresses: [],
      adressesFull: [],
      zonesVacations: [],
      error: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ]),
    zoneVacation() {
      const zv = this.zonesVacations.filter(z => {
        let b = false;
        z.communes.forEach(c => {
          if (c.cp == this.beneficiaire.adresses[0].address_line6_zipcode) {
            b = true;
          }
        });
        return b;
      });
      return zv && zv[0] ? zv[0].nom : "";
    }
  },
  created() {
    const route = useRoute();
    if (route.params.id) {
      axios
        .get(process.env.VUE_APP_API + "/fse/" + route.params.id)
        .then(resp => {
          this.fse = resp.data;
        })
        .catch(() => {
          this.$swal.fire("Une erreur s'est produite.");
        });
    }
    axios
      .get(process.env.VUE_APP_API + "/zone-vacation/list")
      .then(resp => {
        this.zonesVacations = resp.data;
      })
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  methods: {
    goTo(url) {
      this.$router.push(url);
    },
    create: function() {
      this.loading = true;
      this.error = false;
      delete this.fse.rdvs;
      delete this.fse.vacations;
      delete this.fse.zones_vacations;
      let msg = "";

      if (!this.fse.nom.length) {
        this.error = true;
        msg += "Le nom est obligatoire" + "\n";
      }

      if (!this.fse.prenom.length) {
        this.error = true;
        msg += "Le prénom est obligatoire" + "\n";
      }

      if (this.error) {
        this.$swal.fire(msg);
      } else {
        axios
          .post(process.env.VUE_APP_API + "/fse/save", this.fse)
          .then(() => {
            this.showAlert("Le FSE a bien été enregistré.");
          })
          .catch(() => {
            this.$swal.fire("Une erreur s'est produite.");
          });
      }
    },
    showAlert(txt) {
      this.$swal.fire(txt).then(() => this.$router.push("/liste-fse"));
    },
    autoDate(data) {
      this.birthday = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d/]/g, "");
    },
    autoTelMobile(data) {
      this.beneficiaire.telephone_mobile = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/[^\d\s]/g, "");
    },
    autoTelFixe(data) {
      this.beneficiaire.telephone_fixe = data.target.value
        .replace(/^(\d\d)(\d)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/^(\d\d\s\d\d\s\d\d\s\d\d)(\d+)$/g, "$1 $2")
        .replace(/[^\d\s]/g, "");
    }
  }
};
</script>

<style scope lang="scss">
.content {
  .bloc-shadow {
    margin-bottom: 3rem;
  }

  .bloc-infos {
    padding: 2rem 3rem;
    position: relative;

    @media screen and (min-width: 992px) {
      padding: 2rem 4rem 2rem 1rem;
    }

    .col {
      padding: 0 1rem;
    }

    .form-group {
      align-items: center;

      label {
        padding-bottom: 0;
        padding-top: 0;

        @media screen and (min-width: 992px) {
          text-align: right;
        }
      }
    }

    .btn {
      height: 4.5rem;
      padding: 1rem 2rem;
    }
  }

  .bloc-fse {
    padding: 2rem;

    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: space-between;
    }

    h3 {
      margin-bottom: 0;
    }

    .infos {
      display: flex;
      align-items: flex-start;

      &:first-child {
        min-width: 55%;
        flex-basis: 55%;
        margin-bottom: 2rem;

        @media screen and (min-width: 992px) {
          margin: 0;
          padding-right: 3rem;
        }
      }

      &:last-child {
        min-width: 45%;
        flex-basis: 45%;
      }

      img {
        margin-right: 2.5rem;
      }

      div {
        padding-top: 1rem;
      }
    }
  }
}
</style>
